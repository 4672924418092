<template>
    <div>
        <Hooper
            :settings="hooperSettings"
            style="height: 260px; overflow:hidden; margin: 0 -.5rem"
        >
            <Slide
                v-for="(person, i) in favouritePeople"
                :key="i"
            >
                <PersonTile :person="person" />
            </Slide>

            <hooper-navigation
                v-if="2 > 1"
                slot="hooper-addons"
            />
        </Hooper>
    </div>
</template>

<script>
import PersonTile from "@web/components/PersonTile";
import {
    Hooper,
    Slide,
    Navigation as HooperNavigation
} from "hooper";

export default {
    components: {
        PersonTile,
        Hooper,
        HooperNavigation,
        Slide
    },
    data() {
        return {
            favouritePeople: [
                {
                    name: "Nate Franklin",
                    meta: "Fashion Designer • Team Franklin",
                    avatar:
                            "https://pbs.twimg.com/profile_images/974736784906248192/gPZwCbdS.jpg",
                    bg:
                            "https://images.unsplash.com/photo-1581335717810-9cc44b07866b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=900&q=60"
                },
                {
                    name: "Rosemary McKinney",
                    meta: "Fabric Specialist • Team Franklin",
                    avatar: "https://tinyfac.es/data/avatars/A7299C8E-CEFC-47D9-939A-3C8CA0EA4D13-200w.jpeg",
                    bg:
                            "https://images.unsplash.com/photo-1581300641914-9087c61e5331?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=900&q=60"
                },
                {
                    name: "Colin Stark",
                    meta: "UI Designer • Team Halo",
                    avatar:
                            "https://i1.sndcdn.com/avatars-000759718549-1r0e16-t500x500.jpg",
                    bg:
                            "https://images.unsplash.com/photo-1564736676781-d0f57b29f67a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=900&q=60"
                },
                {
                    name: "Michael",
                    meta: "Fashion Designer • Team Franklin",
                    avatar:
                            "https://pbs.twimg.com/profile_images/1056600329662459904/ukegoVAy.jpg",
                    bg:
                            "https://images.unsplash.com/photo-1581294880649-d73698539955?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=900&q=60"
                }
            ],
            hooperSettings: {
                itemsToShow: 1.25,
                vertical: false,
                infiniteScroll: true,
                centerMode: false,
                mouseDrag: false,
                touchDrag: false,
                wheelControl: false,
                mouseScroll: false,
                breakpoints: {
                    700: {
                        itemsToShow: 2.2,
                        itemsToSlide: 1
                    },
                    960: {
                        itemsToShow: 3.2,
                        itemsToSlide: 1
                    },
                    1140: {
                        itemsToShow: 4.2,
                        itemsToSlide: 1
                    }
                }
            }
        };
    }
};
</script>

<style lang="scss" scoped>
    .person-tile {
        outline: none;
        margin: 0 0.5rem;
    }
</style>

<style lang="scss">
    .hooper-prev,
    .hooper-next {
        height: 100%;
    }
    .hooper-prev {
        background: linear-gradient(90deg, var(--background, #{$white}) 0%, rgba(var(--background, #{$white}), 0) 100%);
        left: 0;
        &.is-disabled {
            display: none;
        }
    }

    .hooper-next {
        background: linear-gradient(90deg, rgba(var(--background, #{$white}), 0) 0%, var(--background, #{$white}) 100%);
        right: 0;

        &.is-disabled {
            display: none;
        }
    }
</style>
