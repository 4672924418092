<template>
    <section class="birthdays">
        <div
            v-for="user in users"
            class="user-row"
        >
            <div class="avatar avatar-medium">
                <img
                    :src="user.avatar"
                    alt=""
                />
            </div>
            <div class="text">
                <div class="name">
                    {{ user.name }}
                </div>
                <div class="meta">
                    {{ user.meta }}
                </div>
            </div>
            <div class="slot-custom">
                <div class="birthday-badge">
                    <div class="day">
                        {{ user.day }}
                    </div>
                    <div class="month">
                        {{ user.month }}
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    data() {
        return {
            users: [
                {
                    name: "Colin Stark",
                    meta: "UI Designer • Team Halo",
                    avatar:
                            "https://scontent-frx5-1.cdninstagram.com/v/t51.2885-19/s320x320/16228890_594725164055676_7057958098689851392_a.jpg?_nc_ht=scontent-frx5-1.cdninstagram.com&_nc_ohc=FDvoG04OGEsAX-8jcYR&oh=7971d4578442ead66afee3656c50b400&oe=5ED5F3F0",
                    day: "22",
                    month: "Oct"
                },
                {
                    name: "Nikki Kristoffersen-Haley",
                    meta: "Senior Accountant • Team Echelon",
                    avatar: "https://randomuser.me/api/portraits/women/47.jpg",
                    day: "24",
                    month: "Oct"
                },
                {
                    name: "Yvonne Formentera",
                    meta: "QA Engineer • Team Halo",
                    avatar:
                            "https://pbs.twimg.com/profile_images/752461099807678464/DUhuoeI8.jpg",
                    day: "02",
                    month: "Nov"
                }
            ]
        };
    }
};
</script>

<style lang="scss" scoped>
    .user-row {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        padding: 0.5rem 0;
        border-top: 1px solid var(--low-contrast, #{$light-grey});
        &:first-of-type {
            padding-top: 0;
            border-top: 1px solid transparent;
        }
        .avatar {
            flex: 0 0 auto;
            margin-right: 0.75rem;
        }
        .text {
            flex: 1 1 auto;
            display: flex;
            flex-direction: column;
            width: 100px;
            .name,
            .meta {
                width: 100%;
                word-break: break-all;
                word-wrap: break-word;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            .name {
                font-weight: bold;
            }
            .meta {
                font-size: 0.75rem;
            }
        }
        .birthday-badge {
            background: var(--lowest-contrast, #{$off-white});
            border-radius: var(--border-radius);
            padding: 0.5rem 0.75rem;
            text-align: center;
            .day {
                font-size: 1.5rem;
                font-weight: bold;
            }
            .month {
                color: var(--medium-contrast, #{$mid-grey});
            }
        }
    }
</style>
