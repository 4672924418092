<template>
    <div
        class="activity"
        target="_blank"
        @click="open"
    >
        <slot name="icon">
            <Icon
                v-if="type"
                size="small"
                :type="'activity'"
                :name="type"
                :color="color"
            />
        </slot>
        <div class="text">
            <div class="title">
                <strong>{{ title }}</strong>
                <Icon
                    class="icon"
                    :size="'small'"
                    :name="'external-link'"
                    :color="'rgba(0,0,0,0.3)'"
                />
            </div>
            <div class="meta">
                <slot name="meta">
                    {{ meta }}
                </slot>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from "vue";
import {
    isLinchpexInstalled,
    showUrlInIntegrationWebView
} from "@web/views/atlassian/linchpex";

export default Vue.extend({
    name: "Activity",
    components: {},
    props: {
        title: {
            type: String,
        },
        meta: {
            type: String,
        },
        type: {
            type: String,
        },
        url: {
            type: String,
        }
    },
    data() {
        return {};
    },
    computed: {
        color() {
            if (this.type === "blogpost") {
                return "#00B8D9";
            }
            if (this.type === "page") {
                return "#0052CC";
            }
            return "#666";
        }
    },
    mounted() {
    },
    methods: {
        open() {
            if (isLinchpexInstalled()) {
                showUrlInIntegrationWebView(this.url);
            } else {
                window.open(this.url);
            }
        }
    },
});
</script>

<style lang="scss" scoped>
    .activity {
        text-decoration: none;
        padding: 0 0 1rem;
        display: flex;
        align-items: flex-start;
        cursor: pointer;

        .icon {
            flex: 0 0 auto;
            margin-right: 0.5rem;
            width: 1rem;
        }

        .title {
            display: flex;
            align-items: center;

            .icon {
                margin-left: 0.5rem;
                opacity: 0;
                transition: opacity .2s ease;
            }
            strong {
                margin-top: -5px;
                font-weight: 600;
            }
            .meta {
                font-weight: 400;
            }
        }

        &:hover {
            .icon {
                opacity: 1;
            }
        }
    }
</style>
