<template>
    <div class="group-grid">
        <GroupTile
            v-for="(group, i) in groups"
            :key="group.name"
            :group="group"
        />
    </div>
</template>

<script>
import GroupTile from "@web/components/GroupTile";

export default {
    components: {
        GroupTile
    },
    data() {
        return {
            groups: [
                {
                    name: "Team Franklin",
                    description:
                            "The team around Nate is responsible for most of the design of our apparel and prints. ",
                    members: 16,
                    type: "Team"
                },
                {
                    name: "Frankfurt",
                    description: "Everybody at the Frankfurt headquarter office!",
                    members: 16,
                    type: "Location"
                },
                {
                    name: "Designers",
                    description:
                            "Our designers care about how thinks look, feel and behave.",
                    members: 5,
                    type: "Interest"
                },
                {
                    name: "Coffee Lovers",
                    description: "Everything about the black gold <3 ",
                    members: 5,
                    type: "Interest"
                }
            ]
        };
    }
};
</script>

<style lang="scss" scoped>
    .group-grid {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        .group {
            flex: 0 1 240px;
            margin: 0 1.5rem 1.5rem 0;

            user-select: none;
        }
    }
</style>
