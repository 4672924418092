<template>
    <div class="network">
        <PostIt>{{ $t('example_content' ) }}</PostIt>
        <section class="favourites-container pbottom-large">
            <h2>{{ $t("favourites") }}</h2>
            <YourFavourites />
        </section>
        <div class="grid pbottom-large">
            <div class="col">
                <h2>{{ $t("upcoming_birthdays") }}</h2>
                <Birthdays />
            </div>
            <div class="col">
                <h2>{{ $t("recent_activities") }}</h2>
                <NetworkActivities />
            </div>
        </div>
        <section class="favourites-container pbottom-large">
            <h2>{{ $t("your_groups") }}</h2>
            <YourGroups />
        </section>
    </div>
</template>

<script>
import YourFavourites from "./YourNetwork/YourFavourites";
import Birthdays from "./YourNetwork/Birthdays";
import YourGroups from "./YourNetwork/YourGroups";
import NetworkActivities from "./YourNetwork/NetworkActivities";
export default {
    components: {
        YourFavourites,
        Birthdays,
        YourGroups,
        NetworkActivities
    }
};
</script>

<style lang="scss" scoped>
</style>
