

















import Vue from "vue";
import { BroadcastChannel } from "broadcast-channel";
import { closeIntegrationWebView } from "@web/views/atlassian/linchpex";

export default Vue.extend({
    name: "IntegrationWebView",
    components: {},
    props: {
        url: {
            type: String,
        },
    },
    data() {
        return {
            isMounted: false,
        };
    },
    mounted() {
        setTimeout(() => {
            this.isMounted = true;
        }, 100);
    },
    methods: {
        close() {
            closeIntegrationWebView();
        }
    }
});
