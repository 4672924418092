<template>
    <div
        class="activity-group"
        :class="{ small }"
    >
        <div
            v-if="name || imgUrl"
            class="group-header"
        >
            <img
                v-if="imgUrl"
                class="avatar"
                :src="imgUrl"
            />
            <strong>{{ name }}</strong>
        </div>
        <div :class="{ 'has-img': imgUrl }">
            <slot ></slot>
        </div>
    </div>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
    name: "ActivityGroup",
    props: {
        imgUrl: {
            type: String
        },
        name: {
            type: String
        },
        small: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {};
    },
});
</script>

<style lang="scss" scoped>
    .activity-group {
        padding: 0 2rem 2rem;
        margin: 0 -1.5rem 2rem;
        border-bottom: 1px solid var(--low-contrast, #{$light-grey});

        &.small {
            padding: 0 2rem 1rem;
            margin: 0 -2rem 1rem;
        }

        .has-img {
            padding-left: 2.75rem;
        }

        &:last-child {
            border-bottom: none;
            margin-bottom: 0;
        }

        .group-header {
            display: flex;
            align-items: center;
            padding-bottom: 0.5rem;

            img {
                margin-right: 0.5rem;
                margin-bottom: -0.2rem;
            }
        }
    }

    .card.padding-medium .activity-group {
        padding: 0 1.5rem 1.5rem;
        margin: 0 -1.5rem 1.5rem;
        &:last-of-type {
            padding-bottom: 0;
            margin-bottom: 0;
        }

        .activity:last-of-type {
            padding-bottom: 0;
        }
    }
</style>
