import IntegrationWebView from "@web/views/atlassian/IntegrationWebView.vue";
import { i18n } from "@web/i18n";

export const showUrlInIntegrationWebView = (url: string) => {
    closeIntegrationWebView();
    const vueTarget = document.createElement("div");
    vueTarget.id = "integration-web-view";

    document.body.prepend(vueTarget);
    const extendedWebView = IntegrationWebView.extend({
        i18n,
    });
    new extendedWebView({
        propsData: {
            url
        },
    }).$mount(vueTarget);
};

export const closeIntegrationWebView = () => {
    const elementById = document.getElementById("integration-web-view");
    if (elementById) {
        elementById.remove();
    }
};

export const isLinchpexInstalled = () => document.documentElement.getAttribute("linchpex-installed");
