<template>
    <div
        class="card depth-2 person-tile"
        :to="''"
    >
        <router-link
            class="full-width"
            :to="{ name: 'profile', params: { userId: 1, user: person } }"
        />
        <header>
            <div
                class="user-visual"
                :style="`background-image:url(${person.bg})`"
            >
                <svg
                    class="white-blocker"
                    width="244"
                    height="39"
                    viewBox="0 0 244 39"
                    preserveAspectRatio="none"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M244 39V0L0 39H244Z"
                        fill="white"
                    />
                </svg>
            </div>
            <div class="avatar-group">
                <img
                    class="avatar avatar-large"
                    :src="person.avatar"
                />
                <div class="indicator" ></div>
            </div>
        </header>
        <main>
            <div class="name">
                {{ person.name }}
            </div>
            <div class="meta">
                {{ person.meta }}
            </div>
        </main>
        <footer>
            <div class="actions">
                <a
                    href="#"
                    class="action"
                ><div class="action-bg" ></div>
                    <Icon name="contact-im" /></a>
                <a
                    href="#"
                    class="action"
                ><div class="action-bg" ></div>
                    <Icon name="contact-mail" /></a>
                <a
                    href="#"
                    class="action"
                ><div class="action-bg" ></div>
                    <Icon name="contact-video" /></a>
            </div>
        </footer>
    </div>
</template>

<script>
export default {
    props: {
        person: {
            type: Object,
            default: {
                name: "Person",
                meta: "Job title • Team name",
                avatar:
                        "https://pbs.twimg.com/profile_images/974736784906248192/gPZwCbdS.jpg",
                bg: ""
            }
        }
    }
};
</script>

<style lang="scss" scoped>
    .person-tile {
        position: relative;
        display: block;
        overflow: hidden;
        text-align: center;
        text-decoration: none;
        transition: all 0.2s ease-out;
        height: 240px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        a.full-width {
            z-index: 2;
        }
        header {
            display: flex;
            position: relative;
            .avatar-group {
                position: relative;
                margin: 1rem auto 0;
                .avatar {
                    position: relative;
                    height: 6rem;
                    width: 6rem;
                    z-index: 1;
                    border: 3px solid var(--background, #{$white});
                    -webkit-user-drag: none;
                }
                .indicator {
                    position: absolute;
                    height: 1rem;
                    width: 1rem;
                    bottom: 0.3rem;
                    left: 0.3rem;
                    z-index: 1;

                    background: var(--medium-contrast, #{$mid-grey});
                    border-radius: 100%;
                    border: 3px solid var(--background, #{$white});
                }
            }
            .user-visual {
                position: absolute;
                height: 5rem;
                width: 100%;
                background-size: cover;
                background-position: center center;
                z-index: 0;
                transition: height 0.3s ease;
                .white-blocker {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    height: 20px;
                }
            }
        }
        main {
            margin: 0.5rem;
            flex: 1;
            user-select: none;
        }
        .name {
            font-weight: bold;
        }
        .actions {
            display: flex;
            justify-content: center;
            padding-bottom: 1rem;
            outline: none;
            position: relative;
            z-index: 2;
            .action {
                position: relative;
                text-align: center;
                margin: 0 0.5rem;
                padding: 0.5rem;
                .action-bg {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    border-radius: 100%;
                    background: var(--secondary);
                    transform: scale(0);
                    opacity: 0;
                    transition: all 0.1s ease-in-out;
                }

                &:hover {
                    .action-bg {
                        background: var(--secondary);
                        transform: scale(1);
                        opacity: 1;
                    }
                }
            }
        }

        &:hover {
            .user-visual {
                height: 5.5rem;
            }
        }
    }
</style>

<style lang="scss">
    .action {
        .icon svg path {
            transition: fill 0.2s ease;
        }
    }
    .action:hover {
        .icon svg path {
            fill: var(--secondary-text);
        }
    }
</style>

<docs>
```vue
<person-tile/>
```
</docs>
