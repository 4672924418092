<template>
    <div style="pointer-events: none">
        <ActivityGroup
            img-url="https://scontent-frx5-1.cdninstagram.com/v/t51.2885-19/s320x320/16228890_594725164055676_7057958098689851392_a.jpg?_nc_ht=scontent-frx5-1.cdninstagram.com&_nc_ohc=FDvoG04OGEsAX-8jcYR&oh=7971d4578442ead66afee3656c50b400&oe=5ED5F3F0"
            name="Colin Stark"
        >
            <Activity
                title="changed Job title to UI Designer"
                meta="2 hours ago"
            />
        </ActivityGroup>
        <ActivityGroup
            img-url="https://pbs.twimg.com/profile_images/669512187778498560/L7wQctBt.jpg"
            name="Stockface McStock"
        >
            <Activity
                title="ActivityGroup Updated"
                meta="YEAH"
            />
        </ActivityGroup>
    </div>
</template>

<script>
import ActivityGroup from "@web/views/atlassian/ActivityGroup";
import Activity from "@web/views/atlassian/Activity";

export default {
    components: {
        ActivityGroup,
        Activity
    }
};
</script>

<style lang="scss" >
    .activity-group {
        .group-header {
            margin-bottom: -0.5rem;
        }
        .text {
            .title strong {
                font-weight: 600 !important;
            }
        }
    }
</style>
